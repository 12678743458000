export const environment = {
  production: false,
  infuraId: 'ccc22181380f4f82a81017265f5a525e',
  initial: {
    loginPath: '/',
    loginStatus: '/status',
  },
  cache: {
    isEncrypt: false,
  },
  secretKey: 'RgUjXn2r5u8x/A?D(G+KbPeShVmYp3s6',
  backendServer: { mainApi: 'https://apis-staging-xrb.monkgames.net' },
  authentication: {
    login: 'v1/authen/white-list-login',
    refreshToken: 'v1/authen/refreshtoken',
  },
};
